<template>
  <div>
    <v-card flat>
      <v-card-title class="pa-4 font-weight-bold accent">
        <v-icon left color="primary">mdi-account-multiple</v-icon> Equipe
        Empresa
        <v-spacer></v-spacer>
      </v-card-title>

      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          :headers="headersEquipe"
          :items="equipes"
          :items-per-page="10"
        >
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { fetchClientesEquipes } from "@/api/cliente/cliente_equipe.js";

export default {
  name: "Equipe",

  props: {
    cliente_id: {
      type: Number,
    },
  },

  data() {
    return {
      equipes: [],
      loading: false,
    };
  },

  computed: {
    headersEquipe() {
      return [
        {
          text: "Nome",
          value: "nome",
        },
        {
          text: "Cargo",
          value: "cargo.descricao",
        },
      ];
    },
  },

  methods: {
    getEquipes() {
      this.loading = true;
      return fetchClientesEquipes(`?cliente_id=${this.cliente_id}`)
        .then((response) => {
          this.equipes = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  async mounted() {
    await this.getEquipes();
  },
};
</script>

<style></style>
