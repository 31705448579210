import api from "../axios_service";

export async function fetchClientesEquipes(filtros) {
  const response = await api.get(
    `/cliente/clientes-equipe${filtros ? filtros : ""}`
  );
  return response.data.result;
}

export function putClientesEquipe(id, item) {
  return api.post(`/cliente/clientes-equipe/${id}`, item);
}
